* {
  box-sizing: border-box;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body {
  font-family: "Open Sans", sans-serif;
  background: #f1f3f6;
  overflow-x: hidden !important;
}

.wrap {
  width: 80%;
  margin: auto;
}
.awrap {
  width: 90%;
  margin: auto;
}

.flex {
  display: flex;
  &.jcsb {
    justify-content: space-between;
  }
  &.ci {
    align-items: center;
  }
}

.text {
  &.center {
    text-align: center;
  }
  &.red {
    color: red;
  }
}

.three-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 767px) {
    grid-gap: 10px;
    grid-template-columns: 1fr;
  }
}

.four-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  // @media (max-width: 767px) {
  //   grid-template-columns: 1fr;
  // }
}

.two-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 767px) {
    grid-gap: 10px;
    grid-template-columns: 1fr;
  }
}

.hgap {
  display: inline-block;
  height: 1px;
  width: 20px;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 16px;
}

h3 {
  font-size: 14px;
}

.upload-item {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 6px;
  display: inline-block;
  margin: 10px;
  vertical-align: top;
}

.success-card {
  .check {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: teal;
    color: #fff;
    margin: 10px;
  }
  p.message {
    margin: 10px 0;
  }
  .idn {
    padding: 5px 15px;
    border-radius: 4px;
    border: 1px dashed #ddd;
    letter-spacing: 3px;
    font-size: 18px;
    font-weight: 700;
    background: #fff;
    color: #000;
    margin-top: 10px;
    display: inline-block;
  }
}

.card {
  box-shadow: var(--shadow);
  background: #fff;
  padding: 15px;
  border-radius: 4px;
  margin: 7px 0;
}

.entrance-form-view {
  p {
    font-size: 13px;
  }
}
