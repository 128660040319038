.subpage-head {
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.subpage-head h1 {
  padding: 30px 0;
  font-size: 24px;
  font-weight: 700;
  color: #ddd;
  text-transform: uppercase;
}
