$border_color: rgba(0, 0, 0, 0.09);

#contents {
  .title {
    font-weight: 700;
    font-size: 22px;
  }
}

.contents-table {
  border: 1px solid $border_color;
  border-radius: 6px;
  background: #fff;
  overflow-x: auto;
  max-width: 90vw;

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    th,
    td {
      text-align: center;
      padding: 7px 0;
    }
    thead {
      tr {
        th {
          padding: 10px 15px;
          font-size: 13px;
          @media (max-width: 767px) {
            font-size: 12px;
          }
        }
        border-bottom: 1px solid $border_color;
      }
    }

    tbody {
      .actions {
        i {
          height: 18px;
          svg {
            width: 11px;
            height: 11px;
          }
        }
      }
      td {
        font-size: 13px;
        @media (max-width: 767px) {
          font-size: 12px;
        }
      }
      tr {
        border-bottom: 1px solid $border_color;
        cursor: pointer;

        &:last-of-type {
          border: none;
        }
        &:hover {
          background: rgba(#000, 0.05);
        }
      }
    }
  }
}

.file-item {
  display: inline-block;
  padding: 20px;
  margin: 15px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #ddd;
  a {
    display: block;
    margin-top: 7px;
  }
  img {
    transition: 0.2s;
  }
}
