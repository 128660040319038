.blog-item-img {
  width: 45px;
  height: 45px;
  margin-right: 10px;
  border-radius: 4px;
}

section#dashboard {
  .dashboard-left {
    position: sticky;
    top: 0;
    left: 0;

    @media (max-width: 1000px) {
      left: 0;
      top: 0;
      bottom: 0;
    }
    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 170px;
      img {
        transition: 0.3s;
        padding: 30px 0;
        width: 89px;
        max-width: 85%;
      }
    }
  }

  .dashboard-right {
    flex: 1;
  }
}

nav {
  border-bottom: 1px solid #ddd;
  background: #fff;
  width: 100%;
  .flex {
    height: 70px;
  }
}

ul.sidenavs-items {
  li {
    margin-bottom: 7px;
    a {
      color: rgba(#fff, 0.65);
      display: flex;
      align-items: center;
      padding: 14px 24px;
      padding-right: 10px;
      .item-name {
        margin-left: 10px;
      }
      &.on {
        background: #1890ff;
        color: #fff;
      }
      &:hover {
        color: #fff;
      }
    }
  }
}
