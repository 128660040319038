.homepage {
  background: #02316f;
  min-height: calc(100vh - 80px);
  position: relative;
  .flex {
    // min-height: calc(100vh - 80px);
    padding: 100px 0;
    padding-bottom: 24px;
    color: #ddd;
    @media (max-width: 767px) {
      display: block;
      padding: 20px 0;

      .notice {
        margin-top: 50px;
      }
    }
  }
  .notice {
    h1 {
      margin-bottom: 10px;
    }
    li {
      margin-bottom: 10px;
    }
  }
  .hero {
    img {
      height: 350px;
      @media (max-width: 1230px) {
        height: 250px;
      }
    }
  }

  main {
    color: #fff;
    h1 {
      font-size: 35px;
      color: #fff;
      font-weight: 700;
    }
    h2,
    h3 {
      color: #fff;
    }
  }
}

.apply-now {
  background: #e85d16;
  border: 0;
  padding: 10px 40px;
  border-radius: 100px;
  cursor: pointer;
  color: #fff;
  &:hover {
    background: rgba(#e85d16, 0.9);
  }
}

.rules {
  img {
    max-width: 100%;
    width: 500px;
    margin: 10px 0;
  }
  .cap {
    font-size: 11px;
    max-width: 100%;
    width: 500px;
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    line-height: 20px;
  }
  ul {
    margin: 0;
    padding-bottom: 0;
    li {
      margin: 10px 0;
    }
  }
}
